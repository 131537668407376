import { NgModule } from '@angular/core';
import { LinkPopupComponent } from './link-popup.component';
import 'hammerjs';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    DemoMaterialModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgSelectModule

  ],
  declarations: [
    LinkPopupComponent
  ],
  entryComponents: [
    LinkPopupComponent,
  ],
  exports: [
    LinkPopupComponent
  ]
})

export class  LinkPopup { }